import Routes from 'Routes'

const { about } = Routes

export const generalTerms = [
  {
    text: 'Política de Privacidade',
    link: 'https://www.onze.com.br/politica-de-privacidade'
  },
  {
    text: 'Aviso de privacidade para candidatos',
    link: 'https://www.onze.com.br/blog/wp-content/uploads/2023/04/Aviso-de-privacidade-para-candidatos.docx.pdf'
  },
]

export const socialMediaLinks = [
  {
    link: 'https://www.facebook.com/onzeprevidencia',
    icon: 'facebook',
  },
  {
    link: 'https://www.instagram.com/onzeofficial',
    icon: 'instagram',
  },
  {
    link: 'https://www.linkedin.com/company/onze-oficial',
    icon: 'linkedin',
  },
]

export const usefulLinks = [
  {
    text: 'Quem somos',
    link: about.path
  },
  {
    text: 'Blog',
    link: 'https://www.onze.com.br/blog/'
  },
  {
    text: 'Ferramentas',
    link: 'https://www.onze.com.br/blog/calculadora-da-previdencia/'
  },
  {
    text: 'Contato',
    link: 'mailto:contato@onze.com.br'
  },
]
